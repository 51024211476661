export const configPerEnv = {
  prod: {
    REACT_APP_AUTH0_DOMAIN: 'techminers.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: '9aVPYsACAQXTS9cSS6i9p69Dhv1Z0XpX',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://one.techminers.org/',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',

    REACT_APP_COLLAB_ENDPOINT:
      'wss://collaboration-microservice.one.techminers.org/',
    REACT_APP_COLLAB_API_ENDPOINT:
      'https://collaboration-microservice.one.techminers.org',

    REACT_APP_POLL_API_BASE_URL: 'https://backend.vdr.techminers.com/forms/api',
    REACT_APP_ONE_API_BASE_URL: 'https://backend.one.techminers.org/one/api',
    REACT_APP_REPORT_GENERATOR_API_BASE_URL:
      'https://report-generator.one.techminers.org/',

    REACT_APP_SUPERSET_BASE_URL: 'https://analysis.techminers.org',

    REACT_APP_SUPERSET_PERSON_LANGUAGES_DSOURCE: '3__table',
    REACT_APP_SUPERSET_PERSON_LANGUAGES_SLICE: '8',

    HOTJAR_TRACKING_CODE: 3279158,
  },

  stage: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'gWbTlvbQiDec655MYSYM3NnS5ZiddMTV',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://one.stage.techminers.org/',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',

    REACT_APP_COLLAB_ENDPOINT:
      'wss://collaboration-microservice.one.stage.techminers.org/',
    REACT_APP_COLLAB_API_ENDPOINT:
      'https://collaboration-microservice.one.stage.techminers.org',

    REACT_APP_POLL_API_BASE_URL:
      'https://backend.vdr.stage.techminers.com/forms/api',
    REACT_APP_ONE_API_BASE_URL:
      'https://backend.one.stage.techminers.org/one/api',
    REACT_APP_REPORT_GENERATOR_API_BASE_URL:
      'https://report-generator.one.stage.techminers.org/',

    REACT_APP_SUPERSET_BASE_URL: 'https://analysis.stage.techminers.org',

    REACT_APP_SUPERSET_PERSON_LANGUAGES_DSOURCE: '3__table',
    REACT_APP_SUPERSET_PERSON_LANGUAGES_SLICE: '8',

    HOTJAR_TRACKING_CODE: 3293669,
  },

  stage_1: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'gWbTlvbQiDec655MYSYM3NnS5ZiddMTV',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://one.stage.techminers.org/',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',

    REACT_APP_COLLAB_ENDPOINT:
      'wss://collaboration-microservice.one.stage-1.techminers.org/',
    REACT_APP_COLLAB_API_ENDPOINT:
      'https://collaboration-microservice.one.stage-1.techminers.org',

    REACT_APP_POLL_API_BASE_URL:
      'https://backend.vdr.stage-1.techminers.com/forms/api',
    REACT_APP_ONE_API_BASE_URL:
      'https://backend.one.stage-1.techminers.org/one/api',
    REACT_APP_REPORT_GENERATOR_API_BASE_URL:
      'https://report-generator.one.stage-1.techminers.org/',

    REACT_APP_SUPERSET_BASE_URL: 'https://analysis.stage-1.techminers.org',

    REACT_APP_SUPERSET_PERSON_LANGUAGES_DSOURCE: '3__table',
    REACT_APP_SUPERSET_PERSON_LANGUAGES_SLICE: '8',

    HOTJAR_TRACKING_CODE: 3293669,
  },

  stage_2: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'gWbTlvbQiDec655MYSYM3NnS5ZiddMTV',
    REACT_APP_AUTH0_LOGOUT_URL: 'https://one.stage.techminers.org/',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',

    REACT_APP_COLLAB_ENDPOINT:
      'wss://collaboration-microservice.one.stage-2.techminers.org/',
    REACT_APP_COLLAB_API_ENDPOINT:
      'https://collaboration-microservice.one.stage-2.techminers.org',

    REACT_APP_POLL_API_BASE_URL:
      'https://backend.vdr.stage-2.techminers.com/forms/api',
    REACT_APP_ONE_API_BASE_URL:
      'https://backend.one.stage-2.techminers.org/one/api',
    REACT_APP_REPORT_GENERATOR_API_BASE_URL:
      'https://report-generator.one.stage-2.techminers.org/',

    REACT_APP_SUPERSET_BASE_URL: 'https://analysis.stage-2.techminers.org',

    REACT_APP_SUPERSET_PERSON_LANGUAGES_DSOURCE: '3__table',
    REACT_APP_SUPERSET_PERSON_LANGUAGES_SLICE: '8',

    HOTJAR_TRACKING_CODE: 3293669,
  },

  dev: {
    REACT_APP_AUTH0_DOMAIN: 'techminers-dev.eu.auth0.com',
    REACT_APP_AUTH0_CLIENT_ID: 'gWbTlvbQiDec655MYSYM3NnS5ZiddMTV',
    REACT_APP_AUTH0_LOGOUT_URL: 'http://localhost:3000/',
    REACT_APP_AUTH0_AUDIENCE: 'one/api',

    REACT_APP_COLLAB_ENDPOINT:
      'wss://collaboration-microservice.one.stage.techminers.org/',
    REACT_APP_COLLAB_API_ENDPOINT: 'http://localhost:1234',

    REACT_APP_POLL_API_BASE_URL: '/forms/api',
    REACT_APP_ONE_API_BASE_URL: '/one/api',
    REACT_APP_REPORT_GENERATOR_API_BASE_URL: '/',

    REACT_APP_SUPERSET_BASE_URL: 'http://localhost:8088',

    REACT_APP_SUPERSET_PERSON_LANGUAGES_DSOURCE: '4__table',
    REACT_APP_SUPERSET_PERSON_LANGUAGES_SLICE: '18',

    HOTJAR_TRACKING_CODE: 0,
  },
};

export const getConfig = (name: keyof typeof configPerEnv['dev']) => {
  // https://github.com/react-static/react-static/blob/master/docs/concepts.md#detecting-browser-and-node-environments

  let env: keyof typeof configPerEnv;

  if (typeof document !== 'undefined') {
    // We are in a browser context
    switch (window.location.hostname) {
      case 'localhost':
        env = 'dev';
        break;
      case 'one.stage.techminers.org':
        env = 'stage';
        break;
      case 'one.stage-1.techminers.org':
        env = 'stage_1';
        break;
      case 'one.stage-2.techminers.org':
        env = 'stage_2';
        break;
      default:
        env = 'prod';
    }
  } else {
    // We are in a node context
    throw new Error('Not running in browser, unable to detect environment.');
  }

  return configPerEnv[env][name];
};
